<template>
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 0C3.35685 0 0 3.3871 0 7.5C0 11.6431 3.35685 15 7.5 15C11.6129 15 15 11.6431 15 7.5C15 3.3871 11.6129 0 7.5 0ZM7.5 3.32661C8.19556 3.32661 8.77016 3.90121 8.77016 4.59677C8.77016 5.32258 8.19556 5.86694 7.5 5.86694C6.77419 5.86694 6.22984 5.32258 6.22984 4.59677C6.22984 3.90121 6.77419 3.32661 7.5 3.32661ZM9.19355 11.0081C9.19355 11.2198 9.0121 11.371 8.83064 11.371H6.16935C5.95766 11.371 5.80645 11.2198 5.80645 11.0081V10.2823C5.80645 10.1008 5.95766 9.91935 6.16935 9.91935H6.53226V7.98387H6.16935C5.95766 7.98387 5.80645 7.83266 5.80645 7.62097V6.89516C5.80645 6.71371 5.95766 6.53226 6.16935 6.53226H8.10484C8.28629 6.53226 8.46774 6.71371 8.46774 6.89516V9.91935H8.83064C9.0121 9.91935 9.19355 10.1008 9.19355 10.2823V11.0081Z"
      fill="#303030"
    />
  </svg>
</template>
